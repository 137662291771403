<template>
  <div class="app-container">
    <nav>
      <div class="tips">
        <span>{{nowCompany}}</span>

       <!-- <el-tooltip placement="bottom-start">
          <div slot="content" class="tooltip">
            <p>移交：移交账套后，您将不能再查看本账套，您拥有的本账套权限会转移给接收人</p>
            <p>新增成员：您可以增加新成员加入本账套，并且让他拥有本账套相应的权限</p>
            <p> <i class="iconfont iconyuanquan"></i> 账套管理员</p>
            <p class="textLeft">凭证 | 期末结转 | 账簿 | 报表 | 设置 </p>
            <p> <i class="iconfont iconyuanquan"></i> 主管</p>
            <p class="textLeft">凭证 | 期末结转 | 报表 | 新增账套 | 删除账套 | 科目 | 期初 | 币别 | 凭证字 | 辅助核算</p>
            <p> <i class="iconfont iconyuanquan"></i> 制单人</p>
            <p class="textLeft">凭证 | 期末结转 | 查看账簿和报表 | 新增账套 | 科目 | 期初 | 币别 | 凭证字 | 辅助核算</p>
            &lt;!&ndash; <p> <i class="iconfont iconyuanquan"></i> 出纳</p>
            <p class="textLeft">资金 | 查看凭证、固定资产、账簿和报表 | 新增账套</p> &ndash;&gt;
            <p> <i class="iconfont iconyuanquan"></i> 查看</p>
            <p class="textLeft">查看凭证、账簿和报表 | 新增账套</p>
            <p class="textLeft">提示：如需凭证审核功能，请在“设置—账套—编辑账套”启用凭证审核功能；</p>
            <p class="textLeft">当您审核凭证时，个人设置的姓名即为您的审核人姓名。</p>
          </div>
          <span class="num"><i class="iconfont iconwenhao"></i> 权限说明</span>
        </el-tooltip>-->
      </div>
      <div class="operation">
        <!-- <el-input placeholder="搜索..." v-model="queryValue" class="inputSearch" @keyup.enter.native="searchList">
          <i class="iconfont iconicon1-30" slot="suffix" @click="searchList"></i>
        </el-input> -->
        <!-- v-if="userRole==='01'"  -->
        <el-button @click="toPath" type="primary" class="btn" v-auth='"permissions:add"'><i class="iconfont iconicon1-15"></i> 新增成员</el-button>
      </div>
    </nav>

    <div class="filter-container">
      <books-table :value='queryValue' ref="booksTable" />
    </div>
  </div>
</template>

<script>
  import BooksTable from './modules/booksTable'
  export default {
    components: { BooksTable },
    data() {
      return {
        queryValue: '',
        nowCompany : this.getToken('nowCompany'),
        userRole: this.getToken('userRole'),
      }
    },
    methods: {
      searchList() {
        this.$refs.booksTable.getList();
      },
      toPath() {
        this.$router.push('/permission/permissions/add')
      },
    },
    mounted(){
    }
  }
</script>

<style lang='less' scoped>
  nav {
    height: 60px;
    display: flex;
    padding: 0 18px;
    // line-height: 60px;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    .tips {
      font-size: 16px;
      span {
        margin-left: 2px;
      }
      .num {
        color: #4f71ff;
        font-style: normal;
        margin-left: 8px;
        i{
          font-size: 0.99rem!important;
          margin-right: 0.26rem;
          margin-left: 0.26rem;
          vertical-align: middle;
          margin-bottom: 2px;
          display: inline-block;
        }
      }
    }
    .operation {
      display: flex;
      .inputSearch {
        margin-right: 10px;
        /deep/ .el-input__inner {
          border-radius: 21px !important;
          height: 35px !important;
          // line-height: 35px;
        }
        /deep/ .el-input__suffix {
          right: 16px;
          top: 10px;
          color: #4f71ff;
        }
      }
      .btn{
        width: 120px;
      }
      .iconicon1-15{
        font-size: 12px !important;
        margin-right: 5px;
        margin-left: -5px;
      }
    }
  }

  .tooltip{
    font-size: 13px;
    width: 600px;
    padding: 5px 30px 5px 20px;
    i{
      font-size: 12px;
      margin-right: 10px;
    }
  }
  .textLeft{
    margin-left: 25px;
    line-height: 20px;
  }
  .filter-container {
    height: calc(100% - 95px);
  }
</style>
